import * as React from 'react'

import Layout from '../../components/layout'
import Seo from '../../components/seo'
import Container from '../../components/container'
import DemoForm from '../../components/demo_form'
import Link from '../../components/link'
import HiwSubnav from '../../components/hiw_subnav'
import { StaticImage } from 'gatsby-plugin-image'
import NumberedScroll from '../../components/numbered_scroll'
import ShareDetails1Image from '../../images/HIW_Remote_ShareDetails_01.png'
import ShareDetails2Image from '../../images/HIW_Remote_ShareDetails_02.png'
import GuidePurchaseImage from '../../images/HIW_Remote_GuidePurchase.png'
import EnhanceEngagementImage from '../../images/HIW_Remote_EnhanceEngagement.png'
import Box from '../../components/box'

const HiwRemoteSellingPage = () => (
  <Layout activeNav={true}>
    <Seo
      title='Remote Car Selling'
      description=''
    />
    <div className='flex flex-col justify-center relative bg-black text-white'>
      <StaticImage
        src='../../images/HIW_RemoteCarSelling_Hero.jpg'
        placeholder='blurred'
        alt=''
        className='absolute inset-0'
      />
      <Container className='relative pb-4 pt-5 sm:pb-5 text-white'>
        <h1 className='font-semi-bold text-4vw'>
          Remote Car Selling
        </h1>
        <p className='text-md sm:text-lg mb-0' style={{ maxWidth: '42em' }}>
          Using Roadster’s Express Storefront, appointment-setters become deal concierges. Our remote car-selling features give your team the power to respond to customers immediately, engage them proactively and guide them deeper down the purchase funnel before they set foot inside your store. With the ability to work multiple online deals at once, they’ll be more efficient than ever before. 
        </p>
      </Container>
    </div>
    <HiwSubnav />
    <div className='py-3 sm:py-4'>
      <Container size='lg'>
        <NumberedScroll
          items={[
            {
              title: 'Share Vehicle Details',
              desc: 'Internet and BDC agents can utilize our <strong>Share Details</strong> feature to send customers a link to a selection of inventory or to a specific VIN. Once customers click to land on the VDP, they can immediately begin building their deal online.',
              image: <>
                <img loading='lazy' alt='' src={ShareDetails2Image} />
                <Box
                  as='img'
                  alt=''
                  src={ShareDetails1Image}
                  loading='lazy'
                  css={{
                    transform: 'translateY(40%)',
                    transitionProperty: 'transform, opacity',
                    transitionDuration: '500ms',
                    transitionDelay: '500ms'
                  }}
                />
              </>
            },
            {
              title: 'Guide the Online Car Purchase',
              desc: 'Internet and BDC agents can proactively start orders for customers and prompt them to complete purchase steps prior to visiting the showroom. By encouraging customers to complete most of the process online and working multiple deals at once, agents are able to increase their sales efficiency by <strong>70%</strong>.*',
              link: 'https://go.roadster.com/dealer-impact-study-2.0',
              image: GuidePurchaseImage
            },
            {
              title: 'Enhance Every Engagement',
              desc: '<strong>One-hundred percent</strong> of customers leave digital breadcrumbs and we track them all. Internet and BDC agents can easily see all customer activity that occurs within the Express Store organized on one tab within Roadster Admin. They can then leverage these rich insights to enhance the quality of every customer engagement.',
              image: EnhanceEngagementImage
            }
          ]}
        />
        <p className='text-right text-silver text-sm mt-5 sm:pt-4 mb-0'>
          *Source: Roadster & NADA, “Dealer Impact Study: Part 2,” September 2020.
        </p>
      </Container>
    </div>
    <div className='Section Section--stripe pb-3 sm:pb-4'>
      <Container size='lg'>
        <h2 className='RuleHed mt-0 mb-4 sm:mb-5'>
          Add-On Products<sup>*</sup>
        </h2>
        <div className='grid gap-4 sm:gap-5'>
          <div className='grid gap-3 sm:gap-4 lg:gap-5 sm:grid-cols-2 items-center'>
            <div>
              <StaticImage
                alt=''
                src='../../images/related_products_er.png'
                placeholder='blurred'
              />
            </div>
            <div className='text-base sm:text-md leading-relaxed'>
              <h3 className='text-lg sm:text-xl lg:text-2xl font-semi-bold mt-1'>
                Express Response<sup className='tm'>™</sup>
              </h3>
              <p>
                Our <span className='font-semi-bold'>internet lead response system</span> immediately sends car buyers quality messages to help you engage them 24/7. Emails include VIN-specific pricing from your Express Store and invite customers to begin building their deal online.
              </p>
              <p>
                <Link
                  to='/products/internet_lead_response'
                  className='p-1 -m-1 font-semi-bold'
                  variant='muted'
                  arrowDirection='right'
                  children='Learn more'
                />
              </p>
            </div>
          </div>
          <div className='grid gap-3 sm:gap-4 lg:gap-5 sm:grid-cols-2 items-center justify-center'>
            <div className='sm:order-last'>
              <StaticImage
                alt=''
                src='../../images/related_products_esl.png'
                placeholder='blurred'
              />
            </div>
            <div className='text-base sm:text-md leading-relaxed'>
              <h3 className='text-lg sm:text-xl lg:text-2xl font-semi-bold mt-1'>
                Express Store Live<sup className='tm'>™*</sup>
              </h3>
              <p>
                This <span className='font-semi-bold'>dealer chat solution</span> is managed by Roadster’s live automotive experts. They leverage the features found within your Express Store to instantly answer customers’ questions and engage them in conversations that lead to higher quality opportunities.
              </p>
              <p>
                <Link
                  to='/products/live_chat'
                  className='p-1 -m-1 font-semi-bold'
                  variant='muted'
                  arrowDirection='right'
                  children='Learn more'
                />
              </p>
            </div>
          </div>
          <div className='grid gap-3 sm:gap-4 lg:gap-5 sm:grid-cols-2 items-center'>
            <div>
              <StaticImage
                alt=''
                src='../../images/related_products_et.png'
                placeholder='blurred'
              />
            </div>
            <div className='text-base sm:text-md leading-relaxed'>
              <h3 className='text-lg sm:text-xl lg:text-2xl font-semi-bold mt-1'>
                Express Trade<sup className='tm'>®</sup>
              </h3>
              <p>
                Our proprietary <span className='font-semi-bold'>trade-valuation product</span> uses mobile-first technology to simplify the way customers submit their vehicle info. It gives you complete control over the vehicle’s value and streamlines the process so you can present customers with a firm offer within minutes.
              </p>
              <p>
                <Link
                  to='/products/trade_in_appraisal'
                  className='p-1 -m-1 font-semi-bold'
                  variant='muted'
                  arrowDirection='right'
                  children='Learn more'
                />
              </p>
            </div>
          </div>
          <p className='text-right'>
            <Link
              to='/products/digital_retailing'
              variant='muted'
              className='underline'
              children='*Requires Express Storefront'
            />
          </p>
        </div>
      </Container>
    </div>
    <div className='Section Section--dark'>
      <Container>
        <DemoForm invert={true} />
      </Container>
    </div>
  </Layout>
)

export default HiwRemoteSellingPage
